import React from 'react';
import cmsParser from '../cmsParser';
import htmlParser from 'html-react-parser';
import Image from 'next/image';

import pdfPlaceholder from 'Public/images/document-showcase/pdf-placeholder.jpg';

import Video from 'Components/Video';
import DocumentShowcase from 'Components/DocumentShowcase';

import { CardProps } from 'Modules/Timeline/subcomponents/Card';

class Timeline {
    static cards(items: any[]): CardProps[] {
        return items.map(item => ({
            id: item.sys.id,
            date: item.date,
            heading: item.title,
            childType: item.icon === 'video' ? 'video' : undefined,
            button: {
                label: 'Read more',
                ariaLabel: 'Read hidden content',
                className: `timeline-button`
            },
            children: item.description.map(child => {
                switch (child.type) {
                    case 'richText':
                        return htmlParser(child.value);
                    case 'youTubeVideo':
                        return <Video {...cmsParser.video(child.value)} />;
                    case 'videoTranscript':
                        return <Video {...cmsParser.transcriptEmbed(child.value)} />;
                    case 'image':
                        return (
                            <div
                                style={{
                                    display: 'block',
                                    overflow: 'hidden',
                                    height: 'auto',
                                    width: '100%'
                                }}
                            >
                                <Image
                                    {...cmsParser.image(child.value)}
                                    height={300}
                                    width={500}
                                    style={{ objectFit: 'contain' }}
                                />
                            </div>
                        );
                    case 'asset':
                        return (
                            <DocumentShowcase
                                link={{
                                    href: child.value.sys.uri,
                                    ariaLabel: `${child.value.title} PDF download`,
                                    label: 'Download PDF'
                                }}
                                name={child.value.title}
                                info={{
                                    uploaded: new Date(child.value.sys.version.created)
                                }}
                                placeholder={{
                                    src: pdfPlaceholder
                                }}
                                {...cmsParser.image(child.value)}
                            />
                        );
                }
            })
        }));
    }
}

export default Timeline;

import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import useViewport from 'Common/hooks/useViewport';
import type { BreakpointNames } from 'Theme/types/breakpoints.type';

// TODO set up to handle shortname breakpoints
// TODO write tests

import { Container } from './Hidden.styled';

export interface Props extends Partial<Record<BreakpointNames, boolean>> {
    defaultHidden?: boolean;
    renderHTML?: boolean;
}

const Hidden: React.FC<Props> = props => {
    const { defaultHidden = true, renderHTML = true, children, ...rest } = props;

    if (renderHTML) {
        return (
            <Container
                $hiddenBreakpoints={rest}
                $defaultHidden={defaultHidden}
                children={children}
            />
        );
    } else {
        const [isHidden, setIsHidden] = useState(defaultHidden);
        const { breakpoints } = useTheme();
        const { width } = useViewport();

        useEffect(() => {
            const breakpointValues = Object.keys(rest)
                .map(key => ({ value: breakpoints[key].value, hidden: rest[key] }))
                // Sort into descending order
                .sort((a, b) => b.value - a.value);
            const currentBreakpoint = breakpointValues.find(item => width >= item.value);

            setIsHidden(currentBreakpoint ? currentBreakpoint.hidden : defaultHidden);
        }, [width]);

        if (!isHidden) return <>{children}</>;

        return null;
    }
};

export default Hidden;

import cmsParser from '../cmsParser';
import htmlParser from 'html-react-parser';
import dynamic from 'next/dynamic';

import { ArrowLinkProps } from 'Elements/Link/Arrow';
import { IconButtonProps } from 'Elements/Button/Icon';

import { VideoProps } from 'Components/Video';

export interface OverviewComposerProps {
    text?: React.ReactNode;
    image?: {
        src: string;
        alt: string;
    };
}

export interface CtaComposerProps {
    video?: VideoProps;
    nextEventInformation?: {
        eventHeading: string;
        eventLink: ArrowLinkProps;
        date: Date;
        showEvent: boolean;
    };
    iconButtons?: IconButtonProps[];
}

class CampaignPostgraduate {
    static campaignOverview(items: any[]): OverviewComposerProps[] {
        const content: OverviewComposerProps[] = [];

        items.map(item => {
            switch (item.type) {
                case 'richText':
                    content.push({ text: htmlParser(item.value) });
                    break;
                case 'image':
                    content.push({ image: cmsParser.image(item.value) });
                    break;
                default:
                    return {};
            }
        });

        return content;
    }

    static ctaColumn(items: any[], eventDate: Date): CtaComposerProps[] {
        const content: CtaComposerProps[] = [];

        items.map(item => {
            switch (item.type) {
                case 'video':
                    content.push({ video: cmsParser.video(item.value) });
                    break;
                case 'iconButtonMulitple':
                    content.push({
                        iconButtons: item.value.map(button => ({
                            ...cmsParser.link(button),
                            id: button.link.sys.id,
                            icon: dynamic(() =>
                                import('@solent-university/solent-icons').then(
                                    icons => icons[button.icon.name]
                                )
                            ),
                            variant: 'primaryContrast'
                        }))
                    });
                    break;
                case 'nextEventInformation':
                    content.push({
                        nextEventInformation: {
                            eventHeading: item.value.eventHeading,
                            eventLink: cmsParser.link(item.value.eventLink),
                            date: eventDate,
                            showEvent: item.value.showEvent
                        }
                    });
                    break;
                default:
                    return {};
            }
        });

        return content;
    }
}

export default CampaignPostgraduate;

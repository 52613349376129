import React from 'react';
import useBreakpoint from 'Common/hooks/useBreakpoint';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import { StaticImageData } from 'next/image';
import { Download, Clock } from '@solent-university/solent-icons';

import type { Props as LinkProps } from 'Elements/Link/Link';

import ButtonArrow from 'Elements/Button/Arrow/Arrow';
import LinkArrow from 'Elements/Link/Arrow/Arrow';
import Icon from 'Elements/Icon';

import {
    DocumentShowcaseStyled,
    Content,
    DocumentPreview,
    TopCircle,
    BottomCircle,
    ImageWrapper,
    ImagePlaceholder,
    FileName,
    FileInfo,
    LinkWrapper,
    Link
} from './DocumentShowcase.styled';

export interface Props {
    link: {
        href: string;
        ariaLabel?: string;
        label: string;
    };
    accessibleRequest?: LinkProps;
    name: string;
    info: { size?: string; uploaded: Date };
    placeholder: { src: StaticImageData | string };
}

const DocumentShowcase: React.FC<Props> = props => {
    const { link, accessibleRequest, name, info, placeholder } = props;
    const { breakpoints } = useTheme();
    const isMediumBreakpoint = useBreakpoint(breakpoints.medium.value);
    return (
        <DocumentShowcaseStyled>
            <Content>
                <FileName>{name}</FileName>
                <FileInfo>
                    {isMediumBreakpoint ? (
                        <>
                            {info.size && `${info.size} | `}
                            Uploaded {format(info.uploaded, 'd MMMM yyyy')}
                        </>
                    ) : (
                        <>
                            {info.size && (
                                <>
                                    <Icon icon={Download} /> {info.size}
                                </>
                            )}
                            <Icon icon={Clock} /> {format(info.uploaded, 'd MMMM yyyy')}
                        </>
                    )}
                </FileInfo>
                {isMediumBreakpoint ? <ButtonArrow {...link} /> : <LinkArrow {...link} />}
                {accessibleRequest && (
                    <LinkWrapper>
                        <Link {...accessibleRequest} />
                    </LinkWrapper>
                )}
            </Content>
            <DocumentPreview>
                <TopCircle />
                <BottomCircle />
                <ImageWrapper>
                    <ImagePlaceholder src={placeholder.src} loading="lazy" alt="" />
                </ImageWrapper>
            </DocumentPreview>
        </DocumentShowcaseStyled>
    );
};

export default DocumentShowcase;

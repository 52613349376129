import styled, { css } from 'styled-components';

import { ReactComponent as BrandCircleSVG } from 'Public/graphics/brandCircles/brandCircle.svg';
import BaseLink from 'Elements/Link/Link';
import Image from 'Elements/Image';

export const DocumentShowcaseStyled = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: ${theme.components.documentShowcase.background};
        border-radius: 0.5rem;
        overflow: hidden;
        padding: 0 1rem 2rem 1rem;
        height: 100%;

        @media ${theme.breakpoints.medium.media} {
            padding: 3rem;
            flex-direction: row;
            justify-content: space-around;
            box-shadow: 0 0.45rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        }
    `}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: auto;
    position: relative;
    z-index: 1;
    padding: 0 1rem;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        padding: 0;
    }
`;

export const DocumentPreview = styled.div`
    position: relative;
`;

export const FileName = styled.h3`
    ${({ theme }) => css`
        margin-top: 0.75rem;
        margin-bottom: 0;
        white-space: nowrap;
        width: 15.5rem;
        overflow: hidden;
        text-overflow: ellipsis;

        @media only screen and (max-width: ${theme.breakpoints.mediumSmall.value}px) {
            font-size: 1rem;
        }

        @media ${theme.breakpoints.medium.media} {
            margin-top: 0;
            width: 18rem;
        }

        @media ${theme.breakpoints.large.media} {
            margin-top: 0;
            width: 25rem;
        }
    `}
`;

export const FileInfo = styled.div`
    ${({ theme }) => css`
        margin: 0;
        display: flex;
        align-items: center;
        margin-left: -0.6rem;

        @media only screen and (max-width: ${theme.breakpoints.mediumSmall.value}px) {
            font-size: 0.75rem;
        }

        @media ${theme.breakpoints.medium.media} {
            margin-bottom: 0.5rem;
            margin-left: 0;
        }

        @media ${theme.breakpoints.large.media} {
            margin-bottom: 2rem;
        }
    `}
`;

export const LinkWrapper = styled.div`
    margin-top: 0;

    @media ${({ theme }) => theme.breakpoints.large.media} {
        margin-top: 2rem;
    }
`;

export const Link = styled(BaseLink)`
    ${({ theme }) => css`
        color: ${theme.colors.text.hex};

        @media only screen and (max-width: ${theme.breakpoints.mediumSmall.value}px) {
            font-size: 0.75rem;
        }
    `}
`;

const brandCircleBase = css`
    position: absolute;
    color: #f8f9f9;
    height: 8rem;
    width: 8rem;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        height: 8rem;
        width: 8rem;
    }

    @media ${({ theme }) => theme.breakpoints.large.media} {
        height: 10.5rem;
        width: 10.5rem;
    }
`;

export const TopCircle = styled(BrandCircleSVG)`
    ${brandCircleBase}
    top: 0;
    right: 0;
    transform: translateY(-220%);

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        transform: translate(20%, -25%);
    }

    @media ${({ theme }) => theme.breakpoints.large.media} {
        transform: translate(0, -25%);
    }
`;

export const BottomCircle = styled(BrandCircleSVG)`
    ${({ theme }) => css`
        ${brandCircleBase}
        bottom: 0;
        right: 0;
        transform: translate(-180%, 50%);

        @media ${theme.breakpoints.medium.media} {
            transform: translate(-95%, 25%);
        }

        @media ${theme.breakpoints.large.media} {
            transform: translate(-225%, 15%);
        }
    `}
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;

        @media ${theme.breakpoints.medium.media} {
            padding-right: 1rem;
        }

        @media ${theme.breakpoints.large.media} {
            padding: 0 5rem;
        }
    `}
`;

export const ImagePlaceholder = styled(Image)`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.medium.media} {
            display: block;
            box-shadow: 0 0.45rem 0.75rem 0 rgba(0, 0, 0, 0.15);
            border-radius: 0.5rem;
            position: relative;
            height: auto;
            width: 80%;
            max-width: 15rem;
            flex: 0 0 auto;
        }

        @media ${theme.breakpoints.large.media} {
            max-width: 30rem;
        }
    `}
`;

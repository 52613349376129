import htmlParser from 'html-react-parser';

import cmsParser from 'Common/util/cmsParser';
import { getSlugFromContentType } from 'Common/util/ben';

import type { ImageCaptionProps } from 'Components/ImageCaption';
import type { EventsAgendaProps } from 'Components/Events/Agenda';
import type { EventOpenDayProps } from 'Components/Events/Single/OpenDay';

import type { BenMiniListingProps } from 'Modules/MiniListing/Ben';
import type { BenBannerProps } from 'Modules/Banner/Ben';

export interface MediaHubLandingComposerProps {
    miniListing?: BenMiniListingProps;
    benBanner?: BenBannerProps;
}

export interface NewsBlogComposerProps {
    markup?: React.ReactNode;
    image?: ImageCaptionProps;
    videoEmbed?: React.ReactNode;
    quote?: {
        text: string;
        source?: string;
    };
    openDayBanner?: EventOpenDayProps;
}

export interface EventComposerProps {
    markup?: React.ReactNode;
    image?: ImageCaptionProps;
    videoEmbed?: React.ReactNode;
    agenda?: EventsAgendaProps;
}

class MediaHub {
    static MediaHubLanding = (items: any[]): MediaHubLandingComposerProps[] => {
        const content: MediaHubLandingComposerProps[] = [];

        items.map(item => {
            switch (item.type) {
                case 'mediaHubListing':
                case 'mediaHubListingEvents':
                    content.push({
                        miniListing: {
                            id: item.value.heading,
                            heading: item.value.heading,
                            moreLink:
                                item.value.viewAllLink.label && item.value.viewAllLink.link
                                    ? cmsParser.link(item.value.viewAllLink)
                                    : undefined,
                            items: item.benItems
                                ? item.benItems.map(item => cmsParser.benCard(item))
                                : []
                        }
                    });
                    break;
                case 'mediaHubListingClearing':
                    content.push({
                        miniListing: {
                            id: item.value.heading,
                            heading: item.value.heading,
                            moreLink:
                                item.value.viewAllLink.label && item.value.viewAllLink.link
                                    ? cmsParser.link(item.value.viewAllLink)
                                    : undefined,
                            items: [
                                ...item.benItems.slice(0, -1).map(item => cmsParser.benCard(item)),
                                {
                                    image: cmsParser.image(item.value.clearingCardImage),
                                    preHeading: item.value.clearingCardPreheading,
                                    heading: item.value.clearingCardHeading,
                                    button: {
                                        ...cmsParser.linkButton(item.value.clearingCardLink),
                                        variant: 'campaignSecondary'
                                    }
                                }
                            ]
                        }
                    });
                    break;
                case 'mediaHubListingCustom':
                    content.push({
                        miniListing: {
                            id: item.value.heading,
                            heading: item.value.heading,
                            moreLink:
                                item.value.viewAllLink.label && item.value.viewAllLink.link
                                    ? cmsParser.link(item.value.viewAllLink)
                                    : undefined,
                            items: [
                                cmsParser.benCard(item.value.card1, false),
                                cmsParser.benCard(item.value.card2, false),
                                cmsParser.benCard(item.value.card3, false),
                                cmsParser.benCard(item.value.card4, false)
                            ]
                        }
                    });
                    break;
                case 'featuredBENBanner': {
                    const article = item.value.benItem;

                    content.push({
                        benBanner: {
                            image: item.value.customImage
                                ? cmsParser.image(item.value.customImage)
                                : cmsParser.image(article.heroImage, '/cms/ben'),
                            heading: article.title,
                            description: article.description,
                            date: new Date(article.publishDate),
                            subject:
                                article.subjectArea.length > 1
                                    ? article.subjectArea[0].name
                                    : article.storyClassification
                                    ? article.storyClassification.name
                                    : undefined,
                            link: {
                                href: `/media-hub/${getSlugFromContentType(
                                    article.sys.contentTypeId
                                )}/${article.sys.slug}`
                            }
                        }
                    });
                    break;
                }
            }
        });

        return content;
    };

    static NewsBlog = (items: any[]): NewsBlogComposerProps[] => {
        const content: NewsBlogComposerProps[] = [];

        items.map(item => {
            switch (item.type) {
                case 'markup':
                    content.push({
                        markup: htmlParser(item.value)
                    });
                    break;
                case 'image':
                    content.push({
                        image: {
                            image: cmsParser.image(item.value, '/cms/ben'),
                            borderRadius: true
                        }
                    });
                    break;
                case 'videoEmbed':
                    content.push({
                        videoEmbed: htmlParser(item.value)
                    });
                    break;
                case 'quote':
                    content.push({
                        quote: {
                            text: item.value.text,
                            source: item.value.source
                        }
                    });
                    break;
                case 'openDayBanner':
                    item.value.openDay &&
                        content.push({
                            openDayBanner: {
                                preHeading: item.value.preheading,
                                heading: item.value.heading,
                                event: {
                                    id: item.value.openDay.id,
                                    type: item.value.openDay.type,
                                    date: new Date(item.value.openDay.date),
                                    delivery: item.value.openDay.delivery,
                                    link: {
                                        label: 'Book now',
                                        ariaLabel: 'Book now',
                                        href: item.value.openDay.link,
                                        openInNewTab: true
                                    },
                                    variant: item.value.openDay.variant
                                }
                            }
                        });
                    break;
            }
        });

        return content;
    };

    static Event = (items: any[]): EventComposerProps[] => {
        const content: EventComposerProps[] = [];

        items.map(item => {
            switch (item.type) {
                case 'markup':
                    content.push({
                        markup: htmlParser(item.value)
                    });
                    break;
                case 'image':
                    content.push({
                        image: {
                            image: cmsParser.image(item.value, '/cms/ben'),
                            borderRadius: true
                        }
                    });
                    break;
                case 'videoEmbed':
                    content.push({
                        videoEmbed: htmlParser(item.value)
                    });
                    break;
                case 'agenda':
                    content.push({
                        agenda: {
                            heading: 'Agenda',
                            agendaItems: item.value.map(item => ({
                                startTime: new Date(item.startTime),
                                endTime: new Date(item.endTime),
                                name: item.sessionName,
                                description: item.sessionDescription
                            }))
                        }
                    });
                    break;
            }
        });

        return content;
    };
}

export default MediaHub;

import styled, { css } from 'styled-components';
import Image from 'Elements/Image';
import { SpacingWithBreakpoints } from 'Theme/types/breakpoints.type';

export const Container = styled.div<{
    $hasHeading?: boolean;
    $spacing?: SpacingWithBreakpoints;
}>`
    ${({ theme, $hasHeading, $spacing }) => css`
        background: var(--info-block-bg);
        border-radius: 0.5rem;
        border: 0.0625rem solid ${theme.colors.secondary.tints[2].hex};
        padding: 1.25rem 1rem;

        ${!$hasHeading &&
        css`
            display: flex;
            align-items: center;
        `}

        @media ${theme.breakpoints.medium.media} {
            padding: 1.5rem 1.25rem;
        }

        @media ${theme.breakpoints.large.media} {
            padding: 2rem 2.5rem;
        }

        ${$spacing &&
        Object.keys($spacing).map(
            breakpoint => css`
                @media ${theme.breakpoints[breakpoint].media} {
                    margin: ${$spacing[breakpoint].margin};
                    padding: ${$spacing[breakpoint].padding};
                }
            `
        )}
    `}
`;

export const Icon = styled(Image)`
    ${({ theme }) => css`
        padding-right: 0.75rem;

        @media ${theme.breakpoints.medium.media} {
            padding-right: 1.5rem;
        }
    `}
`;

export const HeadingWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

export const HeadingIconWrapper = styled.div`
    ${({ theme }) => css`
        background: ${theme.colors.tertiary.hex};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    `}
`;

export const HeadingIcon = styled(Image)`
    ${({ theme }) => css`
        width: 2rem;
        height: 2rem;
        color: ${theme.colors.accents[3].hex};

        @media ${theme.breakpoints.large.media} {
            width: 3rem;
            height: 3rem;
        }
    `}
`;

export const Heading = styled.h3`
    margin: 0 0 0 1rem;
    margin-left: 0.75rem;
`;

export const Inner = styled.div`
    & > *:first-child {
        margin-top: 0;
    }
`;

export { default as composerCourse } from './Course';
export { default as composerSolentStory } from './SolentStory';
export { default as composerTimeline } from './Timeline';
export { default as composerOpenDays } from './OpenDays';
export { default as composerFacility } from './Facility';
export { default as composerCampaignPostgraduate } from './CampaignPostgraduate';
export { default as composerFlexibleContentPage } from './FlexibleContentPage';
export { default as composerFlexibleLandingPage } from './FlexibleLandingPage';
export { default as composerStrategy } from './Strategy';
export { default as composerStudentWork } from './StudentWork';
export { default as composerContentCarousel } from './ContentCarousel';
export { default as composerMediaHub } from './MediaHub';
export { default as composerHeroBanner } from './HeroBanner';

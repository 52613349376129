import React from 'react';
import cmsParser from '../cmsParser';
import htmlParser from 'html-react-parser';

import type { VideoProps } from 'Components/Video';
import type { Item as Fact } from 'Components/SupportBlock/Carousel/Carousel';
import type { OverlayImageCardProps } from 'Components/Card/OverlayImage';

export interface CourseOverviewProps {
    text?: React.ReactNode;
    video?: VideoProps;
    overlayImageCard?: OverlayImageCardProps;
}

class Course {
    static facts(facts: any[]): Fact[] {
        const content: Fact[] = [];

        facts.map(fact => {
            const id = fact.sys.id;

            switch (fact.sys.contentTypeId) {
                case 'accreditation':
                    content.push({
                        id,
                        type: 'accreditation',
                        accreditation: {
                            text: fact.name,
                            image: cmsParser.image(fact.image)
                        }
                    });
                    break;
                default:
                    content.push({
                        id,
                        type: 'stat',
                        stat: {
                            stat: fact.stat.stat,
                            text: fact.stat.description
                        }
                    });
                    break;
            }
        });

        return content;
    }

    static overview(items: any[]): CourseOverviewProps[] {
        const content: CourseOverviewProps[] = [];

        items.map(item => {
            switch (item.type) {
                case 'text':
                    content.push({ text: htmlParser(item.value) });
                    break;
                case 'videoTranscript':
                    content.push({ video: cmsParser.transcriptEmbed(item.value) });
                    break;
                case 'youTubeVideo':
                    content.push({ video: cmsParser.video(item.value) });
                    break;
                case 'basicImageCard':
                    content.push({
                        overlayImageCard: {
                            text: item.value.text ? item.value.text : '',
                            link: item.value.link ? cmsParser.link(item.value) : { href: '/' },
                            backgroundImage: cmsParser.image(item.value.backgroundImage, '/cms'),
                            padding: 'tall'
                        }
                    });
                    break;
                default:
                    return {};
            }
        });

        return content;
    }
}

export default Course;

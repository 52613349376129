import cmsParser from '../cmsParser';
import { StudentWorkMedia } from 'Modules/CardSlider/StudentWork/subcomponents/Modal/Modal';

class StudentWork {
    static media(media: any[]): StudentWorkMedia[] {
        const content: StudentWorkMedia[] = [];

        media.map(media => {
            switch (media.type) {
                case 'image':
                    content.push({ image: cmsParser.image(media.value) });
                    break;
                case 'youTubeVideo':
                    content.push({
                        video: {
                            src: media.value.link,
                            image: cmsParser.image(media.value.thumbnail),
                            title: media.value.title
                        }
                    });
                    break;
                default:
                    return {};
            }
        });

        return content;
    }
}

export default StudentWork;

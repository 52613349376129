import styled, { css } from 'styled-components';
import { ReactComponent as BrandCircleSVG } from 'Public/graphics/brandCircles/brandCircle.svg';
import Image from 'Elements/Image';
import { heading2 } from 'Global/Typography';

export const Container = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: ${theme.components.externalLink.background};
        border-radius: 0.5rem;
        overflow: hidden;
        padding: 0 1rem 2rem 1rem;
        height: 100%;

        @media ${theme.breakpoints.medium.media} {
            padding: 3rem;
            flex-direction: row;
            justify-content: space-around;
            box-shadow: 0 0.45rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        }
    `}
`;

export const Title = styled.h3`
    font-size: 1rem;
    margin-top: 2rem;
    line-height: 1.1;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        ${heading2}
        margin-top: 0;
    }
`;

export const Text = styled.div`
    margin-bottom: 0.25rem;
    line-height: 1.1;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        margin-bottom: 2.5rem;
        line-height: 1.4;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: auto;
    position: relative;
    z-index: 1;
    padding: 0 0.5rem;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        padding: 0;
        width: 50%;
    }
`;

export const SideImage = styled.div`
    position: relative;
`;

const brandCircleBase = css`
    ${({ theme }) => css`
        position: absolute;
        color: ${theme.components.externalLink.colors.contrast};
        height: 8rem;
        width: 8rem;

        @media ${theme.breakpoints.medium.media} {
            height: 8rem;
            width: 8rem;
        }

        @media ${theme.breakpoints.large.media} {
            height: 10.5rem;
            width: 10.5rem;
        }
    `}
`;

export const TopCircle = styled(BrandCircleSVG)`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.medium.media} {
            ${brandCircleBase}
            display: block;
            top: 0;
            right: 0;
            transform: translate(0, -20%);
        }

        @media ${theme.breakpoints.large.media} {
            transform: translate(-35%, -15%);
        }
    `}
`;

export const BottomCircle = styled(BrandCircleSVG)`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.medium.media} {
            display: block;
            ${brandCircleBase}
            bottom: 0;
            right: 0;
            transform: translate(-100%, 20%);
        }

        @media ${theme.breakpoints.large.media} {
            transform: translate(-180%, -5%);
        }
    `}
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;

        @media ${theme.breakpoints.medium.media} {
            padding-right: 1rem;
        }

        @media ${theme.breakpoints.large.media} {
            padding: 0 5rem;
        }
    `}
`;

export const TopImage = styled(Image)`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.medium.media} {
            display: block;
            position: relative;
            height: auto;
            max-width: 15rem;
            flex: 0 0 auto;
        }

        @media ${theme.breakpoints.large.media} {
            max-width: 30rem;
        }
    `}
`;

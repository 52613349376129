import React from 'react';
import htmlParser, { HTMLReactParserOptions } from 'html-react-parser';
import colors from 'Theme/lightTheme/colors';
import cmsParser from '../cmsParser';

import { H2 } from 'Elements/Typography';

import QuoteBlock from 'Components/QuoteBlock';

import { ParallaxBannerProps } from 'Modules/Banner/Parallax';

export interface StoryOverviewProps {
    text?: React.ReactNode;
    parallaxQuote?: ParallaxBannerProps;
}

class Story {
    static content(items: any[]): StoryOverviewProps[] {
        const content: StoryOverviewProps[] = [];

        const parserOptions: HTMLReactParserOptions = {
            replace: domNode => {
                if (domNode.children) {
                    switch (domNode.name) {
                        case 'h2':
                            return <H2 size={{ large: '2rem' }}>{domNode.children[0].data}</H2>;
                        default:
                            return domNode;
                    }
                } else {
                    return domNode;
                }
            }
        };

        items.map(item => {
            switch (item.type) {
                case 'text':
                    content.push({ text: htmlParser(item.value, parserOptions) });
                    break;
                case 'parallaxQuote':
                    content.push({
                        parallaxQuote: {
                            image: cmsParser.image(item.value.image).src,
                            button: cmsParser.linkButton({
                                ariaLabel: item.value.linkAriaLabel,
                                label: item.value.linkLabel,
                                link: item.value.link
                            }),
                            children: (
                                <QuoteBlock
                                    children={htmlParser(item.value.quote.text)}
                                    cite={{
                                        author: item.value.quote.source
                                            ? item.value.quote.source
                                            : undefined,
                                        citation: item.value.citation
                                            ? item.value.citation
                                            : undefined
                                    }}
                                    backgroundColor={colors.secondary.tints[0].hex}
                                    textColor={colors.secondaryContrast.hex}
                                    quoteColorLeft={
                                        item.value.quoteIconLeftColor
                                            ? item.value.quoteIconLeftColor
                                            : colors.secondaryContrast.hex
                                    }
                                    quoteColorRight={
                                        item.value.quoteIconRightColor
                                            ? item.value.quoteIconRightColor
                                            : colors.secondaryContrast.hex
                                    }
                                />
                            )
                        }
                    });
                    break;
                default:
                    return {};
            }
        });

        return content;
    }
}

export default Story;

import React from 'react';
import htmlParser from 'html-react-parser';
import cmsParser from 'Common/util/cmsParser';

import type { QuoteBlockProps } from 'Components/QuoteBlock';
import type { VideoProps } from 'Components/Video';
import type { TableProps } from 'Components/Table';

export type StrategyComposerProps = {
    textBlock?: {
        text: React.ReactNode;
    };
    videoComponent?: {
        heading?: string;
        embed: VideoProps;
    };
    blockquote?: QuoteBlockProps;
    image?: {
        src: string;
        alt?: string;
    };
    table?: TableProps;
    strategySummaryKeyPoints?: {
        heading?: string;
        summaryList: string[];
    }[];
};

const StrategyComposer = (pageElements: any[]): StrategyComposerProps[] => {
    const content: StrategyComposerProps[] = [];

    pageElements.map(item => {
        switch (item.type) {
            case 'richText':
                content.push({
                    textBlock: {
                        text: htmlParser(item.value)
                    }
                });
                break;
            case 'quote':
                content.push({
                    blockquote: {
                        children: item.value.text,
                        cite: {
                            author: item.value.source && item.value.source,
                            url: item.value.sourceURL && item.value.sourceURL
                        }
                    }
                });
                break;
            case 'transcriptVideo':
                content.push({
                    videoComponent: {
                        embed: cmsParser.transcriptEmbed(item.value)
                    }
                });
                break;
            case 'image':
                content.push({
                    image: cmsParser.image(item.value)
                });
                break;
            case 'table':
                content.push({
                    table: {
                        headingType: 'h3',
                        heading: item.value.tableHeading && item.value.tableHeading,
                        tableHeadings: item.value.headings.map(heading => heading),
                        tableRows: item.value.data.map(row =>
                            row.type === 'tableRowTextOnly' ? row.value.map(cell => cell.text) : []
                        )
                    }
                });
                break;
            case 'strategySummaryKeyPoints':
                content.push({
                    strategySummaryKeyPoints: item.value.map(item => ({
                        heading: item.heading && item.heading,
                        summaryList: item.summaryList.item
                    }))
                });
                break;
            default:
                return {};
        }
    });

    return content;
};

export default StrategyComposer;

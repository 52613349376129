import styled, { css } from 'styled-components';

export const Wrapper = styled.span`
    ${({ theme }) => css`
        display: block;
        padding-bottom: 0.5rem;

        @media ${theme.breakpoints.medium.media} {
            padding-bottom: 0;
        }
    `}
`;

export const Heading = styled.span`
    ${({ theme }) => css`
        display: block;
        font-size: 1.5rem;
        line-height: 1.2;
        font-weight: 700;
        padding: 0.75rem 0;

        @media ${theme.breakpoints.large.media} {
            font-size: 2.25rem;
        }
    `}
`;

export const Type = styled.span`
    ${({ theme }) => css`
        font-weight: 300;
        display: block;

        @media ${theme.breakpoints.medium.media} {
            display: inline-block;

            &:before {
                content: '- ';
            }
        }
    `}
`;

export const Location = styled.span`
    ${({ theme }) => css`
        display: table;
        font-size: 0.75rem;
        line-height: 1.5;
        font-weight: 300;
        padding: 0 0.75rem;
        color: ${theme.colors.tertiary.hex};
        border: 0.1rem solid ${theme.colors.tertiary.hex};
        border-radius: 1.125rem;
        white-space: nowrap;

        @media ${theme.breakpoints.medium.media} {
            margin: 0;
            position: absolute;
            right: 7.5%;
            top: 50%;
            transform: translateY(-50%);
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 1.125rem;
        }
    `}
`;

export const Anchor = styled.span`
    position: absolute;
    top: -2rem;
`;

export const EventAccordionHeading = styled.h3<{ $boxText?: boolean }>`
    ${({ theme, $boxText }) => css`
        font-size: 1.25rem;
        line-height: 1.5;
        margin: 2rem 0 0;

        ${$boxText &&
        css`
            color: ${theme.colors.accents[14].hex};
            font-size: 1.5rem;

            &:first-of-type {
                margin-top: 0;
            }
        `}
    `}
`;

export const EventAccordionParagraph = styled.p<{ $boxText?: boolean }>`
    ${({ theme, $boxText }) => css`
        margin-top: 0;

        ${$boxText &&
        css`
            color: ${theme.colors.accents[14].hex};

            &:last-of-type {
                margin-bottom: 0;
            }
        `}
    `}
`;

export const EventAccordionList = styled.ul`
    margin-top: 0;
    padding-left: 1.25rem;
`;

import React from 'react';
import cmsParser from '../cmsParser';
import htmlParser from 'html-react-parser';

import { LinkButtonProps } from 'Elements/Button';

import { VideoProps } from 'Components/Video';

export interface FacilityAboutProps {
    text?: React.ReactNode;
    video?: VideoProps; //TODO Remove once videoTranscript live?
    videoTranscript?: VideoProps;
    image?: {
        src: string;
        alt: string;
    };
    link?: LinkButtonProps;
}

class Facility {
    static about(items: any[]): FacilityAboutProps[] {
        const content: FacilityAboutProps[] = [];

        items.map(item => {
            switch (item.type) {
                case 'richText':
                    content.push({ text: htmlParser(item.value) });
                    break;
                case 'youTubeVideo':
                    content.push({ video: cmsParser.video(item.value) });
                    break;
                case 'videoTranscript':
                    content.push({ video: cmsParser.transcriptEmbed(item.value) });
                    break;
                case 'image':
                    content.push({ image: cmsParser.image(item.value) });
                    break;
                case 'button':
                    content.push({ link: cmsParser.linkButton(item.value) });
                    break;
                default:
                    return {};
            }
        });

        return content;
    }
}

export default Facility;

import NextImage from 'next/image';

import cmsParser from 'Common/util/cmsParser';

import Video from 'Components/Video';
import ExternalLink from 'Components/Card/ExternalLink';
import DocumentShowcase from 'Components/DocumentShowcase';

import pdfPlaceholder from 'Public/images/document-showcase/pdf-placeholder.jpg';
import imagePlaceholder from 'Public/images/external-link/student-looking.png';

import type { Slide } from 'Components/Carousel/Carousel';

class ContentCarousel {
    static createSlides(carouselItems: any[]): Slide[] {
        return carouselItems.reduce((slides, item, index) => {
            switch (item.type) {
                case 'videoTranscript':
                    slides.push({
                        id: `youtube_transcript_${index}`,
                        element: <Video {...cmsParser.transcriptEmbed(item.value)} />
                    });
                    break;
                case 'youTubeVideo':
                    slides.push({
                        id: `youtube_video_${index}`,
                        element: <Video {...cmsParser.video(item.value)} />
                    });
                    break;
                case 'image':
                case 'images': {
                    const imageData = cmsParser.image(item.value);
                    slides.push({
                        id: `image_${index}`,
                        element: (
                            <NextImage
                                src={imageData.src}
                                alt={imageData.alt || ''}
                                width={1320}
                                height={742.5}
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        )
                    });
                    break;
                }
                case 'carouselLink':
                    slides.push({
                        id: item.value.link.link.sys.id,
                        element: (
                            <ExternalLink
                                title={item.value.title}
                                text={item.value.description}
                                link={{
                                    href: item.value.link.link.url,
                                    ariaLabel: item.value.link.ariaLabel,
                                    label: item.value.link.label
                                }}
                                placeholder={{ src: imagePlaceholder }}
                            />
                        )
                    });
                    break;
                case 'documentShowcase':
                    slides.push({
                        id: item.value.pdf.sys.id,
                        element: (
                            <DocumentShowcase
                                name={item.value.heading}
                                placeholder={{ src: pdfPlaceholder }}
                                info={{
                                    uploaded: new Date(item.value.pdf.sys.version.created)
                                }}
                                link={{
                                    href: item.value.pdf.sys.uri,
                                    label: 'Download PDF',
                                    ariaLabel: `Download ${item.value.heading}`
                                }}
                            />
                        )
                    });
                    break;
                default:
                    break;
            }
            return slides;
        }, []);
    }
}

export default ContentCarousel;

import React from 'react';
import { ExternalLink as ExternalLinkIcon } from '@solent-university/solent-icons';
import { StaticImageData } from 'next/image';

import Hidden from 'Elements/Hidden';
import ButtonIcon from 'Elements/Button/Icon';
import LinkArrow, { Props as ArrowLinkProps } from 'Elements/Link/Arrow';

import {
    Container,
    Content,
    Text,
    Title,
    SideImage,
    TopCircle,
    BottomCircle,
    ImageWrapper,
    TopImage
} from './ExternalLink.styled';

export interface Props {
    title: string;
    text: string;
    link: ArrowLinkProps;
    placeholder: { src: StaticImageData | string };
}

const ExternalLink: React.FC<Props> = ({ title, text, link, placeholder }) => {
    return (
        <Container>
            <Content>
                <Title>{title}</Title>
                <Text>{text}</Text>
                <Hidden medium={false}>
                    <ButtonIcon
                        href={link.href}
                        label={link.label}
                        ariaLabel={link.ariaLabel}
                        icon={ExternalLinkIcon}
                    />
                </Hidden>
                <Hidden defaultHidden={false} medium={true}>
                    <LinkArrow {...link} />
                </Hidden>
            </Content>
            <SideImage>
                <TopCircle />
                <BottomCircle />
                <ImageWrapper>
                    <TopImage src={placeholder.src} loading="lazy" alt="" />
                </ImageWrapper>
            </SideImage>
        </Container>
    );
};

export default ExternalLink;

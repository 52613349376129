import React from 'react';
import { StaticImageData } from 'next/image';
import { useTheme } from 'styled-components';
import { SpacingWithBreakpoints } from 'Theme/types/breakpoints.type';

import {
    Container,
    Icon,
    Inner,
    HeadingWrapper,
    HeadingIconWrapper,
    HeadingIcon,
    Heading
} from './InformationBlock.styled';

export interface Props {
    children: string | React.ReactNode;
    heading?: string;
    headingType?: 'h2' | 'h3';
    icon?: {
        as?: React.ComponentType<unknown> | keyof JSX.IntrinsicElements;
        src?: string | StaticImageData;
    };
    background?: boolean;
    spacing?: SpacingWithBreakpoints;
}

const InformationBlock: React.FC<Props> = props => {
    const { children, icon, heading, headingType, background = false, spacing } = props;

    const { colors } = useTheme();
    const bgColour = background ? colors.secondary.tints[0].hex : colors.background.hex;

    return (
        <Container
            $hasHeading={!!heading}
            $spacing={spacing}
            style={{ '--info-block-bg': bgColour } as React.CSSProperties}
        >
            {icon && icon.src && !heading && <Icon src={icon.src} alt="" />}
            {heading && (
                <HeadingWrapper>
                    {icon && (
                        <HeadingIconWrapper>
                            {icon.src && <HeadingIcon alt="" src={icon.src} loading="lazy" />}
                            {icon.as && <HeadingIcon as={icon.as} />}
                        </HeadingIconWrapper>
                    )}
                    <Heading as={headingType}>{heading}</Heading>
                </HeadingWrapper>
            )}
            <Inner>{children}</Inner>
        </Container>
    );
};

export default InformationBlock;

import cmsParser from 'Common/util/cmsParser';

import { HeroBannerImageProps } from 'Modules/HeroBanner/MakeWaves/Image';
import { VideoHeroBannerProps } from 'Modules/HeroBanner/MakeWaves/Video';

export interface ImageVideoHeroBannerProps {
    image?: HeroBannerImageProps;
    video?: VideoHeroBannerProps;
}

class HeroBanner {
    static ImageVideo = (
        item: { type: string; value: any },
        options?: { headingColour: string }
    ): ImageVideoHeroBannerProps => {
        switch (item.type) {
            case 'heroBannerImageDefault':
                return {
                    image: {
                        ...cmsParser.heroBannerWave({
                            ...item.value.heroBannerWaveDefault,
                            ...options
                        }),
                        image: cmsParser.image(item.value.image)
                    }
                };
            case 'heroBannerVideoDefault':
                return {
                    video: {
                        ...cmsParser.heroBannerWave({
                            ...item.value.heroBannerWaveDefault,
                            ...options
                        }),
                        video: cmsParser.transcriptEmbed(item.value.videoEmbed)
                    }
                };
            default:
                return {};
        }
    };
}

export default HeroBanner;

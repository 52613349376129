import styled, { css } from 'styled-components';
import type { BreakpointNames } from 'Theme/types/breakpoints.type';

interface ContainerProps {
    $hiddenBreakpoints: Partial<Record<BreakpointNames, boolean>>;
    $defaultHidden: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: none;

    ${({ theme, $hiddenBreakpoints, $defaultHidden }) => css`
        display: ${$defaultHidden ? 'none' : 'block'};

        ${Object.keys($hiddenBreakpoints).map(
            key => css`
                @media ${theme.breakpoints[key].media} {
                    display: ${$hiddenBreakpoints[key] ? 'none' : 'block'};
                }
            `
        )}
    `}
`;

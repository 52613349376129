import React from 'react';
import htmlParser, { HTMLReactParserOptions, domToReact } from 'html-react-parser';

import exclamationMark from 'Public/icons/general/exclaim-circle.svg';

import Link from 'Elements/Link';

import InformationBlock from 'Components/InformationBlock';
import {
    EventAccordionHeading,
    EventAccordionParagraph,
    EventAccordionList
} from 'Components/Accordion/Event/Event.styled';

type OpenDayEventsProps = JSX.Element | JSX.Element[] | '';

class OpenDayEvent {
    static accordion(items: any[]): OpenDayEventsProps[] {
        const content: OpenDayEventsProps[] = [];

        const parserOptions: HTMLReactParserOptions = {
            replace: domNode => {
                if (domNode.children) {
                    switch (domNode.name) {
                        case 'h3':
                            return (
                                <EventAccordionHeading>
                                    {domNode.children[0].data}
                                </EventAccordionHeading>
                            );
                        case 'p':
                            return (
                                <EventAccordionParagraph>
                                    {domNode.children[0].data}
                                </EventAccordionParagraph>
                            );
                        case 'ul':
                            return (
                                <EventAccordionList>
                                    {domToReact(domNode.children)}
                                </EventAccordionList>
                            );
                        default:
                            return domNode;
                    }
                } else {
                    return domNode;
                }
            }
        };

        items.map((item, i) => {
            switch (item.type) {
                case 'text':
                    content.push(
                        <React.Fragment key={`text-${i}`}>
                            {htmlParser(item.value, parserOptions)}
                        </React.Fragment>
                    );
                    break;
                case 'eventPullout':
                    content.push(
                        <InformationBlock
                            key={`pullout-${i}`}
                            icon={{ src: exclamationMark }}
                            background
                        >
                            <EventAccordionHeading $boxText>
                                {item.value.heading}
                            </EventAccordionHeading>
                            <EventAccordionParagraph $boxText>
                                {item.value.description}
                            </EventAccordionParagraph>
                        </InformationBlock>
                    );
                    break;
                case 'contact':
                    content.push(
                        <React.Fragment key={`contact-${i}`}>
                            <EventAccordionHeading>Contact</EventAccordionHeading>
                            <EventAccordionParagraph>
                                <Link
                                    href={`mailto:${item.value.email}`}
                                    ariaLabel="Click to email the open days team"
                                    variant="text"
                                >
                                    {item.value.email}
                                </Link>
                            </EventAccordionParagraph>
                        </React.Fragment>
                    );
                    break;
                default:
                    return '';
            }
        });

        return content;
    }
}

export default OpenDayEvent;
